import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModalOptions, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs';
import { SystemParameterConstants } from 'src/app/model/constants/system-parameter-constants';
import { SystemParameters } from 'src/app/model/systemParameters/system-parameters';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FileService } from 'src/app/services/file/file.service';
import { SystemParametersService } from 'src/app/services/systemParameter/system-parameters.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isTest = false;

  modalOptions: NgbModalOptions;
  closeResult: string = '';

  gotClientHeader = false;
  gotClientHeader2 = false;
  retrievedImage:any;
  retrievedImage2:any;
  role: any = ''; 
  roleID:any = '';
  private isRoleEmployer: any = "EmployerUserVO";
  private isRoleEmployerStaff: any = "EmployerStaffUserVO";
  private isRoleLiaison: any = "WEBERFLIAISON";
  private isRoleAdmin: any = "WEBERFADMIN";
  private isRoleWebMaster: any = "WEBERFWEBMASTER";
  private isRoleAuditor: any = "WEBERFAUDITOR";
  private isPayRollCompany: any = "PayrollCompanyUserVO";
  // params:any = [SystemParameterConstants.HEADER_HTML];
  params: any = [
    SystemParameterConstants.TRUST_NAME,
    SystemParameterConstants.ER_MAINPAGE_HTML,
    SystemParameterConstants.ADMINREPORTS,
    SystemParameterConstants.EMPLOYER_CAN_VIEW_RATES,
    SystemParameterConstants.ER_PROJECT_MAINT,
    SystemParameterConstants.EMPLOYER_CAN_MANAGE_STAFF,
    SystemParameterConstants.CHANGE_PW_AFTER_LOGIN,
    SystemParameterConstants.EFT_ACTIVATED,
    SystemParameterConstants.DEMOGRAPHIC_Q_ENABLE,
    SystemParameterConstants.MANAGE_STAFF_ENABLE,
    SystemParameterConstants.SHOW_CENSUS_IMPORT,
    SystemParameterConstants.SHOW_WEBMSTR_TERMS_OF_USE
  ];
  systemParameters: SystemParameters[] = [];

  constructor(public authService: AuthService, private systemParameterService: SystemParametersService,private router: Router,
    private fileService : FileService,private sanitizer: DomSanitizer,private modalService: NgbModal,) { 
      this.modalOptions = {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      };
    }

  ngOnInit(): void {
    this.loadData();
  }

  // No ASE before login issue
  // Cant grab details
  loadData() {
    //Get System Parameter Details
    this.getSystemParameters();
  }

  public getSystemParameters(): void {
    this.systemParameterService
      .getHeaderDetails(this.params)
      .subscribe(
        (response: any) => {
          this.systemParameters = response.result;
          if(!this.isTest){
            this.getClientLogo("HEADER1");
            this.getClientLogo("HEADER2");
          }
        },
        (error: HttpErrorResponse) => {
          alert(error.error.reason);
        }
      );
  }
  isAffirmative(s: string) {
    if(s != null && (s.toUpperCase().match('Y') || s.toUpperCase().match('YES') || s.match("1"))){
      return true;
    } else{
      return false;
    }
	}

  getClientLogo(str:any){
    this.fileService.retrieve(str)
    .pipe(take(1))
    .subscribe((response:any) => {
      this.fileService.show(response)
        .pipe(take(1))
        .subscribe((response1:any) => {
          let file = new Blob([response1],{type:response.type});
          if(str =='HEADER1') {
            this.retrievedImage = URL.createObjectURL(file);
            this.gotClientHeader = true;  
          }else{
            this.retrievedImage2 = URL.createObjectURL(file);
            this.gotClientHeader2 = true;  
          }
        })
    });
  }
  allowImg(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.retrievedImage);
  }
  allowImg2(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.retrievedImage2);
  }

  logout(){
    this.authService.signout();    
  }

  afterLogin(){
    this.role = this.authService.getSignedinUserRole();
    return (!this.authService.getUserMustAcceptTermsOfUse() && !this.authService.getPassExp());
  }

  // CHECK FOR USER ROLE
  isEmployer(){
    if(this.role == this.isRoleEmployer){
      return true;
    } else {
      return false;
    }
  }
  isEmployerOrStaff(){
    if(this.role == this.isRoleEmployer || this.role == this.isRoleEmployerStaff){
      return true;
    } else {
      return false;
    }
  }
  isAdmin(){
    if(this.role == this.isRoleAdmin){
      return true;
    } else {
      return false;
    }
  }
  isAuditor(){
    if(this.role == this.isRoleAuditor){
      return true;
    } else {
      return false;
    }
  }
  isLiaison(){
    if(this.role == this.isRoleLiaison){
      return true;
    } else {
      return false;
    }
  }
  isWebMaster(){
    if(this.role == this.isRoleWebMaster){
      return true;
    } else {
      return false;
    }
  }
  isPayRoll(){
    if(this.role == this.isPayRollCompany){
      return true;
    } else {
      return false;
    }
  }

  redirectTo(str:string){
    this.router.navigate([str]);
  }
  newTab(str:any){
    window.open(str);
  }
  adminRedirectTo(str:string,destination:string){
    this.router.navigate([
      str,
      { destination: btoa(destination) },
    ]);
  }
  redirectToEmployerReport(str:string){
    this.router.navigate([
      str,
      { type: 'Employer', sub: 'employerContribLedger' },
    ]);
  }
  redirectToEmployeeReport(str:string){
    this.router.navigate([
      str,
      { type: 'Employer', sub:'employeeContribLedger'},
    ]);
  }

  open(content: any) {
    console.log('open');
    this.modalService.open(content, this.modalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  //send to home page every request
  reloadPage() {
    // console.log(window.location.pathname);
    if(!this.isTest){
      if(window.location.pathname == '/home'){
        window.location.reload();
      }else{
        this.router.navigate([
          '/home'
        ]);
      }
    }
 }

 payRollGoToLink(destination:any){
   this.router.navigate([
    '/'+ destination,
    { employerId: btoa(this.authService.getPGUID()) },
  ]);
 }
}
