import { Component, OnInit, Injectable  } from '@angular/core';
import { version, buildDate, commitHash } from "src/environments/version";
@Injectable({
  providedIn: "root",
})
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  currentYear = new Date().getFullYear();

  constructor() { }

  ngOnInit(): void {
  }

  getVersion(): string {
    // return `Version ${version}, commit ${commitHash}, built at ${buildDate}`;

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };

    var x = `${buildDate}`;
    var y = new Date(x).toLocaleDateString(undefined, options);
    return `${version} Built on `+ y;
  }
}