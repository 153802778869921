<mat-toolbar class="footer">
    <p class="copyright">COPYRIGHT&copy; 2004&#45;{{currentYear}} by Trust Benefit Technologies. All Rights
        Reserved.<br />
        WebERF&trade; Version {{getVersion()}}</p>
        <!-- WebERF&trade; Version 4.0.0 @version@ (r@rev@<span id="lblDbVersion"></span>) Built on @date@</p> -->
</mat-toolbar>


<!-- <div class="footer">
    <p class="copyright">COPYRIGHT&copy; 2004&#45;{{currentYear}} by Trust Benefit Technologies. All Rights Reserved.</p>
    <p class="copyright"> WebERF&trade; Version @version@ (r@rev@<span id="lblDbVersion"></span>) Built on @date@</p>
</div> -->
