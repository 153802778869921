import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NgbModalOptions, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Employer } from 'src/app/model/userManagement/employer';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { UserManagementService } from 'src/app/services/userManagement/user-management.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit  {

  employerId:any = '';
  employer!: Employer;
  employerAdditionalDetails:any;
  selectedEmployer: any;
  employerAddress: any;
  employerPhone: any;

  selectedAgreement:any;
  ratesList:any[] = [];
  rateDate:any;


  @ViewChild('viewRateModal') viewRateModal : any;
  page = 1;
	pageSize = 10;

  modalOptions: NgbModalOptions;
  closeResult: string = '';

  loading$ = this.loader.loading$;
  constructor(public loader: SpinnerService,private userManagementService: UserManagementService,private route: ActivatedRoute,
    private modalService: NgbModal) { 
      this.modalOptions = {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
        size:'xl'
      };
  }

  ngOnInit(): void {
    this.getPageParams();
    this.getUserInfo();
  }
  getPageParams():void{
    this.employerId = atob(this.route.snapshot.paramMap.get('employerId')!);
  }

  getUserInfo(): void {
    this.userManagementService
      .getEmployerDetails(this.employerId)
      .subscribe(
        (response: any) => {
          this.employer = response.result.employer;
          this.employerAdditionalDetails = response.result.additionalDetails;
          this.selectedEmployer = response.result.employer;
          this.setEmployerAddress(this.selectedEmployer);
          // this.employerPhone = this.employerAdditionalDetails.phone;
        },
        (error: HttpErrorResponse) => {
          alert(error.error.reason);
        }
      );
  }
  getRatesForLiaison(){
    this.page = 1;
    this.userManagementService
      .getRatesForLiaison(this.employer.guid,this.rateDate == 'null' ? this.rateDate : this.dateToString(new Date(this.rateDate)),this.selectedAgreement.guid)
      .subscribe(
        (response: any) => {
          this.ratesList = response.result;
          this.modalOptions.size = 'xl';
          this.open(this.viewRateModal);
        },
        (error: HttpErrorResponse) => {
          alert(error.error.reason);
        }
      );
  }

  setEmployerAddress(data:any):void{
    for(let i = 0; i < data.locations.length;i++){
      if (data.locations[i].obsolete){
        continue;
      }
      if(data.locations[i].locationType == 'PRIMARY'){
        for(let j = 0; j < data.locations[i].addressHistory.length; j++){
          var effDate = data.locations[i].addressHistory[j].effDate;
          var expDate = data.locations[i].addressHistory[j].expDate;
          if(data.locations[i].addressHistory[j].obsolete){
            continue;
          }
          if(expDate == null){
            if(new Date(effDate!) <= new Date()){
              this.employerAddress = data.locations[i].addressHistory[j].mailingAddress;
              // this.employerPhone = data.locations[i];
            }
          } else {
            if(new Date(effDate!) < new Date() && new Date(expDate) > new Date()){
              this.employerAddress = data.locations[i].addressHistory[j].mailingAddress;
              // this.employerPhone = data.locations[i];
            }
          }
        }
      }
    }
  }

  dateToString(date: any) {
    return (
      date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2)
    );
  }

  open(content: any) {
    console.log('open');
    this.modalService.open(content, this.modalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ratesPageCount(){
    var inital = 0;
    var end = 0;
    if(this.page == 1){
      inital = 1;
      if(this.ratesList.length < 20){
        end = this.ratesList.length;
      }else{
        end = 20;
      }
    } else {
      inital = ((this.page - 1)  * 20)+1;
      if(this.ratesList.length > inital){
        end = this.page * 20;
        if(this.ratesList.length < end){
          end = this.ratesList.length
        }
      }else{
        end = this.ratesList.length
      }
    }

    return inital.toString() +' to '+end.toString();
  }
}