<body>
    <ng-container *ngIf="loading$ | async">
        <app-is-loading></app-is-loading>
    </ng-container>
    <h3 *ngIf="employer">
        Employer Liaison on behalf of {{employer.organizationName}} : {{employer.organizationID}}
    </h3>
    <mat-tab-group mat-stretch-tabs animationDuration="0ms">
        <mat-tab label="Employer Details">
            <table class="tbl" *ngIf="employer">
                <tr>
                    <td class="firstColumn"><span class="required">Employer ID Number*:</span></td>
                    <td>
                        {{selectedEmployer.organizationID}}
                        <!-- <input size="25" maxlength="20" title="Employer Identification Number (No dashes)" [(ngModel)]="selectedEmployer" /> -->
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Contractor License Number:</td>
                    <td>
                        {{selectedEmployer.licenseNumber}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Federal ID Number</td>
                    <td>
                        <ng-container *ngIf="selectedEmployer.federalEIN != null">
                            {{selectedEmployer.federalEIN | slice:0:2}}-{{selectedEmployer.federalEIN | slice:2}}
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn"><span class="required">Employer (Contractor) Name*:</span></td>
                    <td>
                        {{selectedEmployer.organizationName}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Address Line 1:</td>
                    <td>
                        {{employerAddress ? employerAddress.addressLine1: null}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Address Line 2:</td>
                    <td>
                        {{employerAddress ? employerAddress.addressLine2: null}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Address Line 3:</td>
                    <td>
                        {{employerAddress ? employerAddress.addressLine3: null}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">City:</td>
                    <td>
                        {{employerAddress ? employerAddress.city: null}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">State/Province:</td>
                    <td>
                        {{employerAddress ? employerAddress.state: null}} 
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Zip/Postal Code:</td>
                    <td>
                        {{employerAddress ? employerAddress.zipPostalCode: null}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Country:</td>
                    <td>
                        {{employerAddress ? employerAddress.country: null}} 
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Area Code and Phone Number:</td>
                    <td>
                        <!-- {{(employerPhone && employerPhone.phoneNumber) ? ((employerPhone.phoneNumber.areaCode + employerPhone.phoneNumber.localNumber) | phoneNumber) : null}} -->
                        {{(employerAdditionalDetails && employerAdditionalDetails.phone) ? ((employerAdditionalDetails.phone) | phoneNumber) : null}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Extension:</td>
                    <td>
                        <!-- {{(employerPhone && employerPhone.phoneNumber) ? employerPhone.phoneNumber.extension : null}} -->
                        {{(employerAdditionalDetails && employerAdditionalDetails.phoneExt) ? employerAdditionalDetails.phoneExt : null}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Fax Number:</td>
                    <td>
                        <!-- {{(employerPhone && employerPhone.faxNumber) ? ((employerPhone.faxNumber.areaCode + employerPhone.faxNumber.localNumber) | phoneNumber) : null}} -->
                        {{(employerAdditionalDetails && employerAdditionalDetails.fax) ? ((employerAdditionalDetails.fax) | phoneNumber) : null}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Type of Business:</td>
                    <td>
                        {{employerAdditionalDetails.businessType}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">First and Last Name of Payroll Contact:</td>
                    <td>
                        {{employerAdditionalDetails.contactName}}
                    </td>
                </tr>
                <tr>
                    <td class="firstColumn">Payroll Contact Title:</td>
                    <td>
                        {{employerAdditionalDetails.contactTitle}}
                    </td>
                </tr>
            </table>
        </mat-tab>
        <mat-tab label="View Reports"><app-list-reports></app-list-reports></mat-tab>
        <mat-tab label="View Agreements">
            <table class="agreementsTbl" *ngIf="employer">
                <ng-container *ngFor="let item of employer.erCbas; let i = index">
                    <tr>
                        <td style="width:1%">{{i+1}}.</td>
                        <td colspan="3" style="text-align: left;padding-top: 10px;">{{item.agreement.cbaName}} <label>({{item.agreement.cbaId}})</label></td>
                        <td><button color="primary" mat-raised-button (click)="selectedAgreement = item;modalOptions.size = 'm';open(viewRateSelectModal)">View Rates</button></td>
                    </tr>
                    <tr class="alignLeft">
                        <th class="nowrap" style="width:1%"></th>
                        <th class="nowrap">&nbsp;Project ID &nbsp;</th>
                        <th><span class="required nowrap req">&nbsp;Eff Date*</span></th>
                        <th class="nowrap">&nbsp;Exp Date</th>
                        <th><span class="required nowrap req">Alt. ER ID*</span></th>
                        <th class="center nowrap" style="text-align:center">Active</th>
                    </tr>
                    <tr class="alignLeft">
                        <td style="width:1%"></td>
                        <td>
                            <!-- Old WebERF invalid critia
                                <nested:empty property="employer.guid">
                                    guid is never null/empty 
                            -->
                            <!--Project id TODO-->
                            <!-- {{item.projects != undefined && item.projects != null && item.projects[0] != undefined ? item.projects[0].employerProjectID : ''}} -->
                        </td>
                        <td>{{item.effDate | date : 'MM/dd/yyy'}}</td>
                        <td>
                            <ng-container *ngIf="item.expDate != null">
                                {{item.expDate | date : 'MM/dd/yyy'}}
                            </ng-container>
                            <ng-container *ngIf="item.expDate == null">
                                -
                            </ng-container>
                        </td>
                        <td>&nbsp;</td>
                        <td style="text-align: center;">
                            {{item.obsolete ? 'No':'Yes'}}
                        </td>
                    </tr>
                    <tr class="alignLeft" style="border-bottom: 2px solid;">
                        <td style="width:1%"></td>
                        <td><!--job class TODO-->{{item.er_cba_jc != null && item.er_cba_jc[0].jobClass != null ? item.er_cba_jc[0].jobClass.parameterCode : 'All Job Classes'}}</td>
                        <td>{{item.effDate | date : 'MM/dd/yyy'}}</td>
                        <td>
                            <ng-container *ngIf="item.expDate != null">
                                {{item.expDate | date : 'MM/dd/yyy'}}
                            </ng-container>
                            <ng-container *ngIf="item.expDate == null">
                                -
                            </ng-container>
                        </td>
                        <td>{{employer.organizationID}}</td>
                        <td style="text-align: center;">
                            {{item.obsolete ? 'No':'Yes'}}
                        </td>
                    </tr>
                </ng-container>
            </table>
        </mat-tab>
      </mat-tab-group>
</body>



<!-- View Rates Modal -->
<ng-template #viewRateSelectModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">View Rates</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="rateDate = 'null';getRatesForLiaison();modal.close('Save click')">View All</button>
        <br>
        <label>OR</label>
        <br>
        <mat-form-field appearance="fill" style="width: 200px">
            <mat-label>Choose Period</mat-label>
            <input matInput [matDatepicker]="picker1" [readonly]="true" [(ngModel)]="rateDate">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" [disabled]="rateDate == null || rateDate == undefined || rateDate == 'null'" (click)="getRatesForLiaison();modal.close('Save click')">View Rate for Period</button>
    
    </div>
    <div class="modal-footer">
        <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
    </div>
</ng-template>

<!-- Agreement Rates Modal -->
<ng-template #viewRateModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{selectedAgreement.agreement.cbaName}} ({{selectedAgreement.agreement.cbaId}})</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <div style="float: left;">
                <label>{{ratesList.length}} rates found, displaying {{ratesPageCount()}}</label>
            </div>
            <div style="float: right;">
                <pagination-controls (pageChange)="page = $event"></pagination-controls>
            </div>
        </div>
        <div style="white-space: nowrap;overflow:auto;width: 100%;">

            <table class="table table-striped" style="line-height: 4px;">
                <thead>
                    <tr>
                        <th scope="col">Union Local</th>
                        <th scope="col">Job Class</th>
                        <th scope="col">Fund Name</th>
                        <th scope="col">Type</th>
                        <th scope="col">Rate ST</th>
                        <th scope="col">Rate OT</th>
                        <th scope="col">Rate DT</th>
                        <th scope="col">Rate Formula</th>
                        <th scope="col">Rate Condition</th>
                        <th scope="col">Hours Class</th>
                        <th scope="col">Eff. Date</th>
                        <th scope="col">Exp. Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of ratesList | paginate: { itemsPerPage: 20, currentPage: page }">
                        <td>{{item.localId}}</td>
                        <td>{{item.jcId}}</td>
                        <td>{{item.fundName}}</td>
                        <td>{{item.rateType}}</td>
                        <td>{{item.cbaRateValue}}</td>
                        <td>{{item.rateOT}}</td>
                        <td>{{item.rateDT}}</td>
                        <td>{{item.rateFormula}}</td>
                        <td>{{item.rateCondition}}</td>
                        <td>{{item.hoursClass}}</td>
                        <td>{{item.rateEffectiveDate  | date : 'MM/dd/yyy'}}</td>
                        <td>{{item.rateExpDate  | date : 'MM/dd/yyy'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
    </div>
</ng-template>

  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">